import React from 'react'
import Loadable from 'react-loadable'
import { motion } from "framer-motion"


const Oct = Loadable({
    loader: () => import('./Laser'),
    
    loading: ({ timedOut }) =>
      timedOut ? (
        <blockquote>Por favor recarga la pagina.</blockquote>
      ) : (
        <div className="spinner">

        </div>
      ),
    timeout: 100000000,
  })
  
  
  export default class Octoc extends React.Component{
   

   render() 

    {
      return(

 <Oct/>
      
      )
    
  
}
  }