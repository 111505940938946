import styled from 'styled-components'

export const ButtonLink = styled.a`
background-color: var(--color-background);
box-shadow: 0 0 1em 3px var(--color-shadow);
text-shadow: 0 0 1em 3px var(--color-shadow);
  border-radius: 0.4em;
  
  display: flex;
  align-items: center;
  justify-content:center;
  margin-top:0.5em;
  margin-bottom:0.5em;
  width:100%;
  position: relative;

`
export const BuBruh = styled.a`

  color: white;
  border-radius: 0.4em;
  font-size: 2em;
  padding: 0.3em 0.6em;
  transition: 0.3s;
  display: flex;
  align-items: center;
  margin: 5px;


`

export const ButtonGroup = styled.div`
  margin-top: 1em;
  border-radius: 0.5em;
  overflow: hidden;
  width:100%;
  position: fixed !important;
  right:0.1em;
  background-color: var(--color-basen);
  button {
    font-size: 4em;
    border: none;
    outline: none;
    background: var(--color-basen);
    box-shadow: 0 0 1em 3px var(--color-shadow);
    color: white;
    width: max-content;
    :hover {
      background: var(--color-blue-lighter);
    }
    &.active {
      background: var(--color-blue-dark);
      box-shadow: inset 0 0 0.3em black;
    }
  }
`
