import Image from 'gatsby-image'
import styled from 'styled-components'


export const Ptd = styled.hgroup`
  position:absolute;
  justify-self: auto;
margin-left: auto;
width:100% !important;
margin-bottom:0em;
  color: var(--color-texto);
  /* Use flex instead of grid. Else Safari messes up vertical alignment of children. */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 top:5em;
 
 
  flex: 1; /* for filling height between header and footer on 404 page */
  font-size: calc(3em + 1vw);
  text-align: center;
  overflow-x: auto;
  /* prettier-ignore */
  > :not(:first-child):not(svg):not(figcaption) {
    justify-self: center;
    //default 0.7
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0.2em;
    padding: 0.1em 0.4em;

    
  }
  a {
    color: var(--color-lightLink);
  }
`

export const Ptd2 = styled.hgroup`
  position:relative !important;
  z-index: -1;
  justify-self: center;
  
  color: var(--color-texto);
  /* Use flex instead of grid. Else Safari messes up vertical alignment of children. */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom:0em;
  min-height: 12em;
  max-height: 40em;
  flex: 1; /* for filling height between header and footer on 404 page */
  font-size: calc(3em + 1vw);
  text-align: center;
  overflow-x: auto;
  /* prettier-ignore */
  > :not(:first-child):not(svg):not(figcaption) {
    justify-self: center;
    //default 0.7
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0.2em;
    padding: 0.1em 0.4em;
    
  }
  a {
    color: var(--color-lightLink);
  }
`



export const Im = styled(Image).attrs(
  p => !p.fluid && p.src && { as: `img`, src: p.dataURI || p.src }
)`
  position: absolute !important;
  z-index: -1;
  width: 100%;
  height: 42em ;
  object-fit: cover;
  opacity: 1;
  grid-column: 1 / 4;
  margin-bottom:2em;
  
`
export const Imh = styled(Image).attrs(
  p => !p.fluid && p.src && { as: `img`, src: p.dataURI || p.src }
)`
  position: fixed !important;

  width: 100%;
  height: 4.5em ;
  object-fit: cover;
  opacity: 0.6;
  left:0em;
  grid-column: 1 / 4;


  

`
export const Imh2 = styled(Image).attrs(
  p => !p.fluid && p.src && { as: `img`, src: p.dataURI || p.src }
)`
  position: fixed !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  left:0em;
  top:0em;
  grid-column: 1 / 4;
  

  

`
export const Imb = styled(Image).attrs(
  p => !p.fluid && p.src && { as: `img`, src: p.dataURI || p.src }
)`
  position: absolute !important;
  z-index: -1;
  opacity: 1 !important;
  max-width:2em;
  max-height:2em !important; 
top:0em;
right:auto ;
left: auto;


`



export const Imbruh = styled(Image).attrs(
  p => !p.fluid && p.src && { as: `img`, src: p.dataURI || p.src }
)`
  position: center !important;
  z-index: -1;
  opacity: 1 !important;
  width: 100%;

`