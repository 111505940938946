import Demo from "../../../../src/components/Demo";
import saddleProps from "../../../../src/components/postc/saddle";
import fpProps from "../../../../src/components/postc/FM";
import { LazyPlot } from "../../../../src/components/Plotly";
import { Imb, Ptd } from "../../../../src/components/PageTitle/styles";
import triModalProps from "../../../../src/components/postc/triModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'reactstrap';
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from 'react-katex';
import * as React from 'react';
export default {
  Demo,
  saddleProps,
  fpProps,
  LazyPlot,
  Imb,
  Ptd,
  triModalProps,
  FontAwesomeIcon,
  Row,
  Col,
  InlineMath,
  BlockMath,
  React
};