// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boringmath-js": () => import("./../../../src/pages/boringmath.js" /* webpackChunkName: "component---src-pages-boringmath-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-tri-mdx": () => import("./../../../src/pages/posts/tri.mdx" /* webpackChunkName: "component---src-pages-posts-tri-mdx" */),
  "component---src-pages-proyectofisica-js": () => import("./../../../src/pages/proyectofisica.js" /* webpackChunkName: "component---src-pages-proyectofisica-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-ukraine-js": () => import("./../../../src/pages/ukraine.js" /* webpackChunkName: "component---src-pages-ukraine-js" */),
  "component---src-templates-author-posts-js": () => import("./../../../src/templates/author-posts.js" /* webpackChunkName: "component---src-templates-author-posts-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tag-posts-js": () => import("./../../../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-tags-page-js": () => import("./../../../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */)
}

