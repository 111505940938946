import React from 'react'
import Loadable from 'react-loadable'
import { FoldingSpinner } from '../components/Spinners'


const P3d = Loadable({
    loader: () => import('../components/App'),
    
    loading: ({ timedOut }) =>
      timedOut ? (
        <blockquote>Por favor recarga la pagina.</blockquote>
      ) : (
        <div className="spinner">

        <FoldingSpinner />
        </div>
      ),
    timeout: 100000000,
  })
  export const P3dd = ({ layout, style, config, ...rest }) => {
    
 
    return (
    
      <P3d

        layout={
          
          {

          // The next 3 options make the plot responsive.
          font: {
          
            
            size:14,
          },
          autosize: true,
          ...layout,
        }}

        style={{ width: `100%`, ...style, }}
        useResizeHandler
        config={{
          displayModeBar: false,
          showTips: false,
          ...config,
        }}
        {...rest}
      />

    )
  }
  