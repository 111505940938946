import React from 'react'
import useSound from 'use-sound'
import soundUrl from '../images/Bruh.mp3'
import {BuBruh}  from '../styles/Button'
import bruh from '../images/homotopia.png'
import {Imbruh} from '../components/PageTitle/styles'

function Demo() {
  
  const [playbackRate, setPlaybackRate] = React.useState(0.75);

  const [play] = useSound(soundUrl, {
    playbackRate,
    volume: 0.5,
  });

  const handleClick = () => {
    setPlaybackRate(playbackRate + 0.1);
    play();
  };

  return (
    <BuBruh onClick={handleClick}>
      <Imbruh src={bruh} 
          
          />
    </BuBruh>
  );
}
export default Demo