import { MDXProvider } from '@mdx-js/react'
import React from 'react'
import { LazyPlot } from '../Plotly'
import { P3dd} from '../P3d'
import  Octoc from '../Octocom'
import { DocsGrid } from '../../styles'
import { GlobalStyle } from './styles'
import SEO from '../seo'

const components = { LazyPlot, DocsGrid, P3dd, Octoc}

export const Providers = ({ children }) => (
  <MDXProvider components={components}>
    {children}
  </MDXProvider>
)

export function PageComponents({ children, ...rest }) {
  
  return (
    <>
      <GlobalStyle />
      
      <SEO/>

      {children}
      
    </>
  )
}
